import "./App.css";
import MainRoute from "./route/MainRoute";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();

  useEffect(() => {

    let metaDescription = document.querySelector("meta[name='description']");

    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }

    // Update description based on the current route or state
    if (location.pathname === "/") {
      metaDescription.content = "Namaha VFX is one of the World's leading visual effects companies.We provide a range of services, including rotoscope, paint, match move,and visual effects shots.";
    } else if (location.pathname === "/about") {
      metaDescription.content = "Award-winning Namaha VFX crafts unforgettable stories with high-quality visual effects for films, commercials, and OTT platforms. Experts in post-production.";
    } else if (location.pathname === "/services") {
      metaDescription.content = "Looking for the best VFX services? Namaha VFX Studio specializes in creating breathtaking visuals that captivate audiences worldwide. Contact us for a free consultation!";
    } else if (location.pathname === "/blog") {
      metaDescription.content = "Explore our blogs for the latest updates and insights on cutting-edge VFX techniques and industry trends. Dive into our world of creativity and technology today!";
    } else if (location.pathname === "/contact") {
      metaDescription.content = "Contact us today to streamline converting your imagination into reality. We are Namaha VFX, a leading VFX studio dedicated to bringing your visions to life.";
    } else if (location.pathname === "/best-vfx-software") {
      metaDescription.content = "Discover the top 10 VFX software for stunning visual effects with Namaha VFX. Perfect tools for beginners and pros to elevate your projects!";
    } else if (location.pathname === "/rotoscoping-services") {
      metaDescription.content = "Explore top rotoscopy techniques every VFX artist should know. Master your craft with expert insights from Namaha VFX and take your visual effects to the next level.";
    } else if (location.pathname === "/matchmoving-vfx") {
      metaDescription.content = "Learn all about matchmoving in VFX with our complete guide. Discover matchmoving, how it works, and why it’s essential for seamless visual effects.";
    } else if (location.pathname === "/best-vfx-rotomation-software") {
      metaDescription.content = "Check out the top VFX rotomation software tailored for your creative projects. Learn to choose the best tools for precise, high-quality visual effects that bring your vision to life.";
    }
  }, [location]);
  return (
    <div>
      <MainRoute />
    </div>
  );
}
export default App;
