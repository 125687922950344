import React from "react";
import Navbar from "../navbar/Navbar";
import Services from "./Services";
import TrustedClients from "../trustedclients/TrustedClients";
import Footer from "../footer/Footer";
import LetsTalk from "../contact/LetsTalk";
import ContactBtn from "../contact/ContactBtn";
import { Helmet } from "react-helmet-async";

function OurServices() {
  return (
    <div className="bg-black">
      <Helmet>
        <title>Best VFX Services | Namaha VFX Studio</title>
        <meta
          name="description"
          content="Looking for the best VFX services? Namaha VFX Studio specializes in creating breathtaking visuals that captivate audiences worldwide. Contact us for a free consultation!"
        />
      </Helmet>
      <div className="bg-img pb-[10vh]">
        <Navbar />
        <div className="md:mt-[10vh] mt-[6vh]">
          <h1 className="patuaone text-white lg:text-[80px] md:text-[40px] text-[30px] text-center font-medium">
            Our Services
          </h1>
          <p
            className=" text-white text-center lg:text-[20px] md:text-[14px] text-[10px] w-full 2xl:max-w-[65%] xl:max-w-[60%] lg:max-w-[75%] md:max-w-[80%] max-w-[90%] mx-auto"
            style={{ fontWeight: "100" }}
          >
            Join us in exploring the limitless possibilities of visual
            storytelling. Namaha VFX is one of the world's leading visual effect
            companies composed of creative, smart, and innovative brains. We
            provide a range of services, including rotoscope, paint, match move,
            visual effects shots, etc.
          </p>
          <ContactBtn />
        </div>
      </div>
      <div className="lg:mt-[10vh] mt-5">
        <Services />
      </div>
      <div className="xl:mt-[15vh] my-[10vh] ">
        <LetsTalk />
      </div>

      <div className="md:mt-[15vh] mt-[7vh]">
        <Footer />
      </div>
    </div>
  );
}
export default OurServices;
