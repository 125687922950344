import React from 'react'
import Navbar from '../navbar/Navbar'
import ContactBtn from '../contact/ContactBtn'
import About from './About'
import OURTEAM from '../../assets/images/Mask group (7).png'
import TrustedClients from '../trustedclients/TrustedClients'
import LetsTalk from '../contact/LetsTalk'
import Footer from '../footer/Footer'
import IMAGE1 from '../../assets/images/Group 47576.png'
import IMAGE2 from '../../assets/images/Group 47577.png'
import IMAGE3 from '../../assets/images/Group 47578.png'
import RAHUL from '../../assets/images/rahul.jpg'
import KRISHNAN from '../../assets/images/krishna.jpg'
import SURYA from '../../assets/images/surya.jpg'
import CHANDRA from '../../assets/images/chandra.jpg'
import DIVYA from '../../assets/images/divya.jpeg'
import { Helmet } from 'react-helmet-async'

const teamMembers = [
  {
    name: 'Rahul Upadhyay',
    designation: 'Partner/ Co Founder',
    image: RAHUL,
  },
  {
    name: 'Surya',
    designation: 'Co Founder/ Studio Head',
    image: SURYA,
  },
  {
    name: 'Krishnan J.',
    designation: 'Executive Producer',
    image: KRISHNAN ,
  },
  {
    name: 'Chandra Pandian',
    designation: 'Production Head',
    image: CHANDRA,
  },
  {
    name: 'Divya Ram',
    designation: 'Production Manager',
    image: DIVYA,
  },
  // {
  //   name: 'Employee Name ',
  //   designation: 'Designation ',
  //   image: "https://namahavfx.com/wp-content/uploads/2023/09/Untitled-design-4-615x1024.jpg",
  // },
]
const data = [
  {
    image: IMAGE1,
    title: 'Certified Expertise',
    description: 'Our team of certified VFX experts is ready to transform your ideas into high-quality visual reality.',
  },
  {
    image: IMAGE2,
    title: 'Customized Solutions',
    description: 'We deliver customized solutions to meet our clients needs at every stage of production.',
  },
  {
    image: IMAGE3,
    title: 'Confidentiality & Security',
    description: 'We adhere strictly to best practices for complete confidentiality without any security concerns. ',
  },
]


function AboutVfx() {
  
  return (
    <div className='bg-black'>
     <Helmet>
        <title>Best Visual Effects Studio in India | Namaha VFX</title>
        <meta name="description" content="Learn more about Namaha VFX, our team of experts, and why we are the premier choice for visual effects and post-production services." />
      </Helmet>
      <div className='bg-img '>
        <Navbar />
        <div className='md:mt-[10vh] mt-[6vh] '>
          <h1 className='patuaone text-white lg:text-[80px] md:text-[40px] text-[30px] text-center font-medium'>About VFX</h1>
          <p className=' text-white text-center lg:text-[20px] md:text-[14px] text-[10px] w-full xl:max-w-[60%] lg:max-w-[90%] md:max-w-[93%] max-w-[90%] mx-auto' style={{ fontWeight: '300' }}>
            Namaha VFX is renowned for making history on the big screen. As an award-winning post-production visual effects studio,
            we specialize in crafting heartfelt stories that leave a lasting impression on viewers. We create high-quality series for
            feature films, commercials, and OTT platforms and complete rotoscoping productions.</p>
          <ContactBtn />
        </div>
      </div>
      <div className='my-[10vh]'>
        <About />
      </div>
      <div className='max-w-1440 w-[90%] mx-auto'>
        <h1 className='text-white lg:text-[40px] text-[28px] font-bold'>Why Choose Us?</h1>
        <p className='text-white mt-2 lg:text-[20px] text-[10px] md:mb-[3vh] mb-[4vh]' style={{ fontWeight: 100 }}>VFX studios have become one of the essential parts of various sectors, including games, advertising, film, and television industries. Namaha VFX is a premier visual effects studio providing high-quality services to clients worldwide. Our innovative approach and commitment to excellence make us the perfect choice for any VFX project.</p>
      </div>
      <div className='max-w-1440 w-[90%] mx-auto grid grid-cols-2 md:grid-cols-3 md:gap-4'>
        {data.map((item, index) => (
          <div key={index} className="p-3 bg-vector text-center 2xl:h-[600px] xl:h-[500px] lg:h-[350px] md:h-[260px] h-[210px] lg:mt-4 mt-0">
            <div className='flex items-center justify-center rounded-[50%] bg-[#9F1E0B] xl:w-[186px] lg:w-[100px] md:w-[80px] w-[40px] xl:h-[186px] lg:h-[100px] md:h-[80px] h-[40px] mx-auto xl:mt-[8vh] md:mt-[2vh]' >
              <img src={item.image} alt='img' className=" xl:w-[76px] lg:w-[40px] md:w-[30px] w-[20px] xl:h-[76px] lg:h-[40px] md:h-[30px] h-[20px]" />

            </div>
            <h1 className='text-white font-bold text-center xl:text-[28px] lg:text-[22px] md:text-[16px] text-[13px] 2xl:pt-4 lg:pt-2 pt-1 '>
              {item.title}
            </h1>
            <p className='text-center text-white  md:pt-2 xl:text-[20px] lg:text-[18px] text-[10px] mx-auto w-[80%]'>
              {item.description}
            </p>
          </div>
        ))}
      </div>
      <div className='max-w-1440 w-[90%] mx-auto'>
        <h1 className='text-white lg:text-[40px] text-[28px] font-bold  md:mt-[10vh] mt-[10vh]'>Meet Our Team</h1>
        <p className='text-white md:mt-2 lg:text-[20px] text-[10px] mb-3' style={{ fontWeight: 100 }}>Our team believes in EXCELLENCE, whether it's a high-definition video, animation, rotoscope, or any other service. We have a solid team of organized, strategic thinkers, creative minds, revolutionary experts, and steady trendsetters who are here to create VALUE for you.</p>
      </div>
      <div className='max-w-1440 w-[92%] mx-auto grid grid-cols-2 md:grid-cols-3 md:gap-9 gap-2'>
          <div className=" xl:p-4 lg:p-4 p-3 bg-vector2 w-full h-full text-center shadow-lg" >
            <img src={RAHUL} alt='RotoScopy' className="w-[100%]  2xl:h-[410px] xl:h-[390px] lg:h-[270px] md:h-[190px] h-[150px] 2xl:pt-0 pt-1" style={{clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0 99%, 0 10%)'}}/>
            <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>Rahul Upadhyay</p>
            <p className='text-center text-white xl:text-[20px] lg:text-[10px] text-[10px]'>Partner/ Co Founder</p>
          </div>
          <div className=" xl:p-4 lg:p-4 p-3 bg-vector2 w-full h-full text-center shadow-lg" >
            <img src={SURYA} alt='RotoScopy' className="w-[100%]  2xl:h-[410px] xl:h-[390px] lg:h-[270px] md:h-[190px] h-[150px] 2xl:pt-0 pt-1" style={{clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0 99%, 0 10%)'}}/>
            <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>Surya</p>
            <p className='text-center text-white xl:text-[20px] lg:text-[10px] text-[10px]'>Co Founder/ Studio Head</p>
          </div>
          <div className=" xl:p-4 lg:p-4 p-3 bg-vector2 w-full h-full text-center shadow-lg" >
            <img src={KRISHNAN} alt='RotoScopy' className="w-[100%]  2xl:h-[410px] xl:h-[390px] lg:h-[270px] md:h-[190px] h-[150px] 2xl:pt-0 pt-1" style={{clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0 99%, 0 10%)'}}/>
            <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>Krishnan J.</p>
            <p className='text-center text-white xl:text-[20px] lg:text-[10px] text-[10px]'>Excutive Producer</p>
          </div>
          <div className=" xl:p-4 lg:p-4 p-3 bg-vector2 w-full h-full text-center shadow-lg" >
            <img src={CHANDRA} alt='RotoScopy' className="w-[100%]  2xl:h-[410px] xl:h-[390px] lg:h-[270px] md:h-[190px] h-[150px] 2xl:pt-0 pt-1" style={{clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0 99%, 0 10%)'}}/>
            <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>Chandra Pandian</p>
            <p className='text-center text-white xl:text-[20px] lg:text-[10px] text-[10px]'>Production Header</p>
          </div>
          <div className=" xl:p-4 lg:p-4 p-3 bg-vector2 w-full h-full text-center shadow-lg" >
            <img src={DIVYA} alt='RotoScopy' className="w-[100%]  2xl:h-[410px] xl:h-[390px] lg:h-[270px] md:h-[190px] h-[150px] 2xl:pt-0 pt-1" style={{clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0 99%, 0 10%)'}}/>
            <p className='text-white font-bold text-center xl:text-[28px] lg:text-[23px] md:text-[16px] text-[12px] mt-2'>Divya Ram</p>
            <p className='text-center text-white xl:text-[20px] lg:text-[10px] text-[10px]'>Production Manager</p>
          </div>
      </div>
      <div className='xl:mt-[15vh] mt-[10vh]'>
        <LetsTalk />
      </div>
      <div className='md:mt-[15vh] mt-[7vh]'>
        <Footer />
      </div>
    </div>
  )
}

export default AboutVfx