import React, { useState,useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import HomePage from '../screens/HomePage/HomePage';
import ContactUs from '../screens/contact/ContactUs';
import OurServices from '../screens/services/OurServices';
import OurWorkMainPage from '../screens/ourwork/OurWorkMainPage';
import ShowReel from '../screens/showreel/ShowReel';
import OurBlogs from '../screens/ourblogs/OurBlogs';
import AboutVfx from '../screens/AboutNamaha/AboutVfx';
import EnterPassword from '../screens/showreel/EnterPassword';
import Blog1 from '../screens/ourblogs/Blog1';
import Blog2 from '../screens/ourblogs/Blog2';
import Blog3 from '../screens/ourblogs/Blog3';
import Blog4 from '../screens/ourblogs/Blog4';
import { HelmetProvider } from 'react-helmet-async';

function MainRoute() {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [passwordVerified, setPasswordVerified] = useState(
    sessionStorage.getItem('authenticated') === 'true'
  );

  
  const location = useLocation();

  const handlePasswordSubmit = (isVerified) => {
    if (isVerified) {
      sessionStorage.setItem('authenticated', 'true');
      setPasswordVerified(true);
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/services" element={<OurServices />} />
          <Route path="/ourWork" element={<OurWorkMainPage />} />
          <Route path="/blog" element={<OurBlogs />} />
          <Route path="/about" element={<AboutVfx />} />
          <Route path="/best-vfx-software" element={<Blog1 />} />
          <Route path="/rotoscoping-services" element={<Blog2 />} />
          <Route path="/matchmoving-vfx" element={<Blog3 />} />
          <Route path="/best-vfx-rotomation-software" element={<Blog4 />} />
          <Route
            path="/showreel"
            element={
              passwordVerified ? (
                <ShowReel />
              ) : (
                <Navigate to="/enterPassword" replace state={{ from: location.pathname }} />
              )
            }
          />
          <Route
            path="/enterPassword"
            element={<EnterPassword onPasswordSubmit={handlePasswordSubmit} />}
          />
        </Routes>
      </HelmetProvider>
    </div>
  );
}

export default MainRoute;