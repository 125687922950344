import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet-async";
function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    comment: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form Submitted:", formData);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      comment: "",
    });
  };
  return (
    <div className="bg-black">
      <Helmet>
        <title>Contact Us | Namah VFX Studio</title>
        <meta
          name="description"
          content="Contact us today to streamline converting your imagination into reality. We are Namaha VFX, a leading VFX studio dedicated to bringing your visions to life."
        />
      </Helmet>
      <div className="bg-img pb-[10vh]">
        <Navbar />
        <div className="md:mt-[10vh] mt-[6vh]">
          <h1 className="patuaone text-white lg:text-[80px] md:text-[40px] text-[30px] text-center font-medium">
            Contact Us
          </h1>
          <p
            className="text-white text-center lg:text-[20px] md:text-[14px] text-[10px] w-full 2xl:max-w-[70%] xl:max-w-[60%] lg:max-w-[75%] md:max-w-[80%] max-w-[90%] mx-auto"
            style={{ fontWeight: "300" }}
          >
            Giving wings to your ideas is challenging, but we simplify the
            process. Contact us today to streamline converting your imagination
            into reality. We are Namaha VFX, a leading VFX studio dedicated to
            bringing your visions to life.
          </p>
        </div>
      </div>
      <form
        className=" max-w-1440 w-[90%] mx-auto md:my-[10vh]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3 mb-6 md:w-1/2">
            <label
              className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2"
              htmlFor="firstName"
            >
              Enter your First Name
            </label>
            <input
              className="appearance-none block w-full bg-[#272727] border border-white text-gray-700 lg:h-[60px] h-[50px] rounded  px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="firstName"
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-full px-3 mb-6 md:w-1/2">
            <label
              className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2"
              htmlFor="lastName"
            >
              Enter Your Last Name
            </label>
            <input
              className="appearance-none block w-full bg-[#272727] border border-white text-gray-700 lg:h-[60px] h-[50px] rounded  px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="lastName"
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="w-full px-3 mb-6 md:w-[100%] -mx-3">
          <label
            className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2"
            htmlFor="email"
          >
            Enter Your Email
          </label>
          <input
            className="appearance-none block xl:w-[102%] lg:w-[103%] md:w-[104%] w-[108%] bg-[#272727] border border-white text-gray-700 lg:h-[60px] h-[50px] rounded  px-3 mb-3 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="email"
            name="email"
            type="text"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-full px-3 mb-6 md:w-[100%] -mx-3">
          <label
            className="block tracking-wide lg:text-[20px] md:text-[15px] text-[13px] text-white font-normal mb-2"
            htmlFor="comment"
          >
            Comment Or Message
          </label>
          <textarea
            className="appearance-none block xl:w-[102%] lg:w-[103%] md:w-[104%] w-[108%] bg-[#272727] border border-white text-gray-700 rounded md:h-[200px] h-[150px] md:py-4 py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="comment"
            name="comment"
            type="text"
            value={formData.comment}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div>
          <button className="border border-[#9F1E0B] bg-[#1c0c09] text-white md:w-[179.66px] w-[130px] lg:h-[57px] h-[40px] font-thin lg:text-[20px] md:text-[14px] text-[12px] rounded-[8px]">
            Submit
          </button>
        </div>
      </form>
      <div className="div  md:mt-[18vh] mt-[7vh]">
        <Footer />
      </div>
    </div>
  );
}

export default ContactUs;
